import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import Teachi from "views/pages/Teachi.js";
import Portal from "views/pages/Portal.js";
import About from "views/pages/About.js";
import Kits from "views/pages/Kits.js";
import Classes from "views/pages/Classes.js";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/portal"
        render={(props) => <Portal {...props} />}
      />
      <Route
        path="/teachi"
        render={(props) => <Teachi {...props} />}
      />
      <Route
        path="/about"
        render={(props) => <About {...props} />}
      />
      <Route
        path="/kits"
        render={(props) => <Kits {...props} />}
      />
      <Route
        path="/classes"
        render={(props) => <Classes {...props} />}
      />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
