/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import MainFooter from "components/Footers/MainFooter.js";

function ProfilePage() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h3 className="title">
                Come Learn with Us!<br />
              </h3>
            </div>
          </div>
          <Row>
            <Col className="description text-center">
              <h5>
                Our classes cover a wide variety of topics from all areas of STEM! We can work on an individual basis or in a group setting. Below are a few of the classes that we offer!
              </h5>
              <br />
            </Col>
          </Row>
          <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Craig's Concepts to Creations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "3" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Let's Collaborate!
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="text-center" activeTab={activeTab}>
            <TabPane tabId="1" id="follows">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/Activities/Heat Airflow.png")}
                  style={{ width: "50%", marginBottom: "20px" }}
                />
              <p>
                <h5>This class takes a "bottom up" approach to product design and engineering. We formulate an idea, determine why we're building it, and then dive into the details of making it a reality! Questions that are often asked in the class:</h5>
                <h6>
                    <b>What problem are we trying to solve?</b>
      <br/>
                    <b>Who needs this problem solved?</b>
      <br/>
                    <b>What do we need to build this?</b>
      <br/>
                    <b>How much is it going to cost us?</b>
      <br/>
                    <b>How long will it take?</b>
      <br/>
                    <b>Do we need to do things that we've never done/learned before?</b>
      <br/>
                 </h6>
                <h5>Once these questions are answered, a project plan is designed and we get to work!</h5>
              </p>
    <br/>
            <Button
              className="btn-round"
              color="success"
              href="https://book.ms/b/ClassInquiries@robotyze.org"
              target="_blank"
            >
              Book Now!
            </Button>
            </TabPane>
            <TabPane className="text-center" tabId="2" id="following">
              <h5 className="text-muted">We offer general group sessions where students will work both independently and in teams to solve problems! 
    <br/>
    <br/>
    Each class generally starts with covering a concept, and then applying the concept.
    </h5>
    <br/>
            <Button
              className="btn-round"
              color="success"
              href="https://book.ms/b/ClassInquiries@robotyze.org"
              target="_blank"
            >
              Book Now!
            </Button>
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <MainFooter />
    </>
  );
}

export default ProfilePage;
