/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="section-light"
        style={{ marginBottom: "15%" }}
      >
      {/*
        "url(" + require("assets/img/antoine-barres.jpg").default + ")",
      */}
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <img
                alt="..."
                className="img-rounded img-responsive"
                src={require("assets/img/robotyze-logo.png")}
                style={{ width: "60%", marginTop: "25%", marginBottom: "15%" }}
              />
              <h2 className="info-title">Ready to Learn?</h2>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
