/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import MainFooter from "components/Footers/MainFooter.js";


function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="main">
        <div className="section section-dark text-center">
          <Container>
            <br/>
            <Row >
          <Col className="ml-auto mr-auto text-center" md="13">
                <h2 className="title">Ready to Learn?</h2>
                <h5 className="description">
                    TEaCHi is now in beta testing for certain students and teachers! This tool uses cutting edge AI to learn an individual's educational needs and help them to grow in their journey. 
                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="info"
                  href="/portal"
                >
                  Login
                </Button>
              </Col>
            </Row>
            <br/>
            <br/>
            <Row >
                <Col className="ml-auto mr-auto text-center" md="13">
                    <img
                      alt="..."
                      className="img-rounded img-responsive"
                      src={require("assets/img/Teachi.png")}
                      style={{ width: "50%" }}
                    />
                </Col>
            </Row>
            <br/>
            <br/>
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-album-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Beautiful Visuals</h4>
                    <p className="description">
                      Spend your time conveying new ideas. You don't have to
                      think of implementing.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">New Ideas</h4>
                    <p>
                      Learn or teach in new and unique ways.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Statistics</h4>
                    <p>
                      Check out how the examples have worked over time.
                    </p>
                    <Button className="btn-link" color="info" href="#pablo">
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <br/>
            <br/>
          </Container>
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default LandingPage;
