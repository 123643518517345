/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionExamples() {
  return (
    <>
      <div className="section section-dark"  style={{marginBottom: "5%"}} >
        <Container>
          <Row className="example-page">
            <h2 className="info-title text-center" style={{marginBottom: "5%"}} >Choose your Style</h2>
          <Col className="text-center" md="6">
              <a href="/classes" target="_blank">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/Activities/Heat Airflow.png")}
                  style={{ width: "100%" }}
                />
              </a>
              <Button
                className="btn-round"
                color="default"
                href="/classes"
              >
                Classes
              </Button>
            </Col>
            <Col className="text-center" md="6">
              <a href="/kits" target="_blank">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/Activities/LED array.png")}
                  style={{ width: "100%" }}
                />
              </a>
              <Button
                className="btn-round"
                color="default"
                href="/kits"
              >
                At-Home Kits
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col className="text-center" md="6" colspan="2">
              <h2 className="info-title" style={{marginBottom: "10%"}} >Need some Help?</h2>
              <a href="/teachi" target="_blank" >
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/Teachi.png")}
                  style={{ width: "100%", marginBottom: "2%"}}
                />
              </a>
              <Button
                className="btn-round"
                color="default"
                href="/teachi"
              >
                Teachi
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
