/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AboutPageHeader from "components/Headers/AboutPageHeader.js";
import MainFooter from "components/Footers/MainFooter.js";

function ProfilePage() {
  // const [activeTab, setActiveTab] = React.useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <AboutPageHeader />

      <div className="section section-light text-center">
      <div className="name">
          <h2 className="title">
            What is Robotyze?  <br />
          </h2>
        </div>
        <Row>
      <Col/>
        <Col className="ml-auto mr-auto text-center" md="8">
          <p className="text-center">
          Robotyze was originally founded from the question <b>"Why do students find games fun and school boring?"</b>. Shortly after starting his work in curriculum design and teaching, Craig was intrigued with why students could have so much fun with some things, but absolutely dread others. To learn more, he started testing some of his own hypotheses: he spent a large amount of time developing proof-of-concepts games and lessons to see what drew a student to WANT to learn.
            </p>
      <br/>
          <p className="text-center">
      Overall, he found that <b>the core content/immersiveness of a topic isn't necessarily what draws or pushes away students</b>. It's often <b>the delivery method</b> of that content which will truly cause a student to be <b>excited and engaged</b>. This belief is incoporated into the products and lessons produced by Robotyze to this day!  
          </p>
        </Col>
        <Col/>
      </Row>

            <Container>
              <Row>
                <Col md="12">
                  <h3 className="title">
                    So, who's this Craig guy?  <br />
                  </h3>
                  <Card className="card-profile card-plain">
                    <div className="card-avatar">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          src={
                            require("assets/img/faces/craig.jpg")
                          }
                        />
                      </a>
                    </div>

                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="author">
                          <CardTitle tag="h4">Craig</CardTitle>
                          <h6 className="card-category">Founder</h6>
                        </div>
                      </a>
                      <p className="card-description text-center">
                        Craig has bounced around in a number of different occupations within the education and engineering fields.
                        Throughout his journey, he often found himself being called the "proof of concept guy" by his coworkers and bosses.
                        Additionally, his responsibilities have included: designing and building products, writing hundreds of STEM lessons, 
                        and leading/building successful engineering teams. His 12 years of experience have motivated him to emphasize to
                        his students that effective communication, transparency, and persistence can lead to success.
                      </p>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-just-icon btn-neutral ml-1"
                        color="link"
                        href="https://www.linkedin.com/in/craig-wilkinson-b3429078/"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        <MainFooter />
        </>
    );
  }
export default ProfilePage;
