/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import paypalButton from '../../assets/img/express-checkout-hero-sg.png';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import MainFooter from "components/Footers/MainFooter.js";
//import ReactDOM from "react-dom"
//const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });


function ProfilePage() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
//  const createOrder = (data) => {
//    // Order is created on the server and the order id is returned
//    return fetch("/my-server/create-paypal-order", {
//      method: "POST",
//       headers: {
//        "Content-Type": "application/json",
//      },
//      // use the "body" param to optionally pass additional order information
//      // like product skus and quantities
//      body: JSON.stringify({
//        cart: [
//          {
//            sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
//            quantity: "YOUR_PRODUCT_QUANTITY",
//          },
//        ],
//      }),
//    })
//    .then((response) => response.json())
//    .then((order) => order.id);
//  };
//  const onApprove = (data) => {
//     // Order is captured on the server and the response is returned to the browser
//     return fetch("/my-server/capture-paypal-order", {
//      method: "POST",
//       headers: {
//        "Content-Type": "application/json",
//      },
//      body: JSON.stringify({
//        orderID: data.orderID
//      })
//    })
//    .then((response) => response.json());
//  };
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>

          <div className="owner">
            <div className="name">
              <h1 className="title">
                Bring the Fun Home! <br />
              </h1>
            </div>
          </div>
          <Row>
          <Col className="ml-auto mr-auto text-center" md="13">
              <p className="text-center">
              Engage and excite your children with monthly projects that allow them to challenge their understanding of the world.
              </p>
            </Col>
          </Row>
                    <hr />
              <Row>
                <Col className="mx-auto" md="10">
                      <Row>
                        <Col className="mx-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/Activities/Smores Bridge.jpeg")
                            }
                          />
                        </Col>
                        <Col lg="7" md="4" xs="4">
                          <h5>
                            <b>Investigate</b> 
                            <br />
                            <large>$10 - 3 tests to learn about the world</large>
                          </h5>
                            <br />
                            <h6>
                            Example:
                            <br />
                            <small>A brother and sister each received an edible lesson about structural engineering. The brother (4 years old) learned about impulse and load testing on a cookie structure. The sister (7 years old) learned about structural adhesives when trying to make a smores bridge!</small>
                            <br />
                            <br />
                        </h6>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="2" xs="2">
                          <FormGroup check>
      {/*        <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign" />
                            </Label> */}
                          </FormGroup>
                        </Col>
                      </Row>
                    <hr />
                      <Row>
                        <Col className="mx-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/Activities/CNC Machining.png")
                            }
                          />
                        </Col>
                        <Col lg="7" md="4" xs="4">
                          <h5>
                            <b>Experiment</b> 
                            <br />
                            <large>$30 - 4 activities to formulate and test hypotheses</large>
                          </h5>
                            <br />
                            <h6>
                            Example:
                            <br />
                            <small> A student was provided with an introduction to 3D design and CNC machining. He then created some 3D models, emailed them to the Robotyze team, and was able to have them machined for him! When his first design failed, he iterated on it to improve it and ultimately understand the complexities of CNC machining. Finally, the items were mailed to him to keep! </small>
                            <br />
                            <br />
                        </h6>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="2" xs="2">
                          <FormGroup check>
      {/*  <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign" />
                            </Label> */}
                          </FormGroup>
                        </Col>
                      </Row>
                    <hr />
                      <Row>
                        <Col className="mx-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/Activities/LED regular.jpg")
                            }
                          />
                        </Col>
                        <Col lg="7" md="4" xs="4">
                          <h5>
                            <b>Innovate</b> 
                            <br />
                            <large>$50 - A project to build that will allow an expression of creativity </large>
                          </h5>
                            <br />
                            <h6>
                            Example:
                            <br />
                            <small> A student received a small computer and had to properly wire it to an LED array. Once the student powered it up, she was able to start a web server and upload images to create an LED slideshow! However, a 24 by 24 LED array can be a bit of a challenge for images to display properly.</small>
                            <br />
                            <br />
                        </h6>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="1" md="2" xs="2">
                          <FormGroup check>
      {/* <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign" />
                            </Label> */}
                          </FormGroup>
                        </Col>
                      </Row>
                </Col>
              </Row>
                    <hr />
              <Row>
                <Col className="mx-auto" md="3">
      {/*                  <PayPalButton 
                        createOrder={(data) => createOrder(data)}
                        onApprove={(data) => onApprove(data)}
                    /> */}
 <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="C8Z8S7BQN3Q9U" />
  <table>
    <tr>
      <td>
        <input type="hidden" name="on0" value="Kits"/>
        Kits
      </td>
    </tr>
    <tr>
      <td>
        <select name="os0">
          <option value="Investigate Kit">
            Investigate Kit $10.00 USD
          </option>
          <option value="Experiment Kit">
            Experiment Kit $30.00 USD
          </option>
          <option value="Innovate Kit">
            Innovate Kit $50.00 USD
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <input type="hidden" name="on1" value="Special Requests"/>
        Special Requests
      </td>
    </tr>
    <tr>
      <td>
        <input type="text" name="os1" maxLength="200" />
        <br/>
        <br/>
        NOTE: All kits have an additional $5 Shipping Charge
      </td>
    </tr>
  </table>
  <input type="hidden" name="currency_code" value="USD" />
  <input width="80%" type="image" src={paypalButton} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
</form>
            </Col>
            </Row>
        </Container>
      </div>
      <MainFooter />
    </>
  );
}

export default ProfilePage;
