/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container, NavLink } from "reactstrap";

function MainFooter() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <NavLink
                  data-placement="bottom"
                  href="https://twitter.com/robotyze"
                  target="_blank"
                  title="Follow us on Twitter"
                >
                  <i className="fa fa-twitter" />
                  <p className="d-lg-none">Twitter</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-placement="bottom"
                  href="https://www.facebook.com/Robotyze"
                  target="_blank"
                  title="Like us on Facebook"
                >
                  <i className="fa fa-facebook-square" />
                  <p className="d-lg-none">Facebook</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-placement="bottom"
                  href="https://www.instagram.com/robotyze_/"
                  target="_blank"
                  title="Follow us on Instagram"
                >
                  <i className="fa fa-instagram" />
                  <p className="d-lg-none">Instagram</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-placement="bottom"
                  href="https://www.github.com/Robotyze"
                  target="_blank"
                  title="Star on GitHub"
                >
                  <i className="fa fa-github" />
                  <p className="d-lg-none">GitHub</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  data-placement="bottom"
                  href="mailto:info@robotyze.org"
                  target="_blank"
                  title="Email Us"
                >
                  <i className="fa fa-envelope" />
                  <p className="d-lg-none">Email</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        </Row>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="https://www.termsfeed.com/privacy-policy/3e56c92e502e1920f4cb4cc9ceacd854"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.termsfeed.com/terms-conditions/e954eb6dddcfd6e927d652fe19a2092b"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
              </li>
              <li className="justify-flex-end">
                <div className="credits ml-auto">
                  <span className="copyright">
                    © {new Date().getFullYear()} Robotyze LLC
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;
